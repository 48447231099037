import { useEffect, useState, useCallback } from "react"
import "./contact.component.css"
import emailjs from "@emailjs/browser"

const ContactComponent = (props) => {
  const [sending, setSending] = useState()
  const [sent, setSent] = useState()
  const [error, setError] = useState()
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const { active, onDone, onCancel } = props

  // Setup emailjs
  useEffect(() => {
    emailjs.init({
      publicKey: "edulFb1RNrDcvhhWg",
      blockHeadless: true,
      limitRate: {
        id: "app",
        throttle: 10000,
      },
    })
  }, [])

  const handleSend = async () => {
    setSending(true)
    setSent(false)
    setError(false)

    // Validate email
    const validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    if (!email.match(validRegex)) {
      setError("Please enter a valid email address and try again.")
      setSending(false)
      setTimeout(() => setError(false), 5000)
      return
    }

    try {
      await emailjs.send("service_4xaj27f", "template_hruvhsp", {
        from_email: email,
        message,
      })

      setSent(true)
      setTimeout(() => setSent(false), 5000)
      setEmail("")
      setMessage("")
    } catch (e) {
      setError("There was an error sending your message - please try again.")
      setTimeout(() => setError(false), 5000)
    } finally {
      setSending(false)
    }
  }

  useEffect(() => {
    if (onCancel) {
      clearContent()
    }
  }, [onCancel])

  const clearContent = () => {
    setError(false)
    setSending(false)
    setSent(false)
    setEmail("")
    setMessage("")
  }

  const handleCancel = () => {
    clearContent()
    onDone()
  }

  const handlePreventScroll = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    return false
  }, [])

  useEffect(() => {
    if (!active) return

    const el = document.querySelector(".revive-app-main")
    el.addEventListener("wheel", handlePreventScroll)
    el.addEventListener("touchmove", handlePreventScroll)

    return () => {
      el.removeEventListener("wheel", handlePreventScroll)
      el.removeEventListener("touchmove", handlePreventScroll)
    }
  }, [active, handlePreventScroll])

  return (
    <div className="revive-contact">
      {error && <div className="revive-toast error">Uh oh. {error}</div>}
      {sent && (
        <div className="revive-toast success">
          Nice. Your message has been sent successfully!
        </div>
      )}
      <div className="revive-contact-form">
        <div className="revive-contact-input">
          <h6>To: </h6>
          <h6>revivebathworks.com</h6>
        </div>
        <div className="revive-contact-input">
          <h6>From: </h6>
          <input
            type="email"
            placeholder="Enter your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="revive-contact-input">
          <textarea
            placeholder="Message:"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
      </div>
      <div className="revive-contact-actions">
        <button
          className="revive-primary-btn green"
          onClick={handleSend}
          disabled={!email || !message}
        >
          {sending ? "Sending..." : "Send Message"}
        </button>
        <button onClick={handleCancel}>Cancel</button>
      </div>
    </div>
  )
}

export default ContactComponent
