import { useGSAP } from "@gsap/react"
import { installIcon, matIcon, tileIcon, waterIcon } from "../icons"
import "./process.component.css"
import gsap from "gsap"

const ProcessComponent = (props) => {
  const { id } = props

  useGSAP(() => {
    gsap.fromTo(
      ".revive-process .process-info",
      {
        yPercent: 10,
        opacity: 0,
      },
      {
        yPercent: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: ".revive-process",
          start: "top 50%",
          end: "bottom 90%",
          scrub: true,
          scroller: ".revive-app-main",
        },
      }
    )

    const tiles = document.querySelectorAll(".process-grid li")
    tiles.forEach((e, i) => {
      const offset = 20 + (tiles.length - i) * 6
      const end = 20 + offset
      gsap.fromTo(
        e,
        {
          yPercent: -10,
          translateZ: -5,
          opacity: 0,
        },
        {
          opacity: 1,
          yPercent: 0,
          translateZ: 0,
          scrollTrigger: {
            trigger: ".revive-process",
            start: `top ${offset}%`,
            end: `bottom 90%+=${end}px`,
            scrub: 3,
            scroller: ".revive-app-main",
          },
        }
      )
    })
  }, [])
  return (
    <div className="revive-process" id={id}>
      <div className="process-info">
        <h2>process</h2>
        <p>
          while many companies might cut corners to save on costs, we believe
          that true luxury and lasting value come from using the finest
          materials available and properly installing them. from high-end
          fixtures to premium tiles, every component of our renovations is
          chosen for its durability and aesthetic appeal.
        </p>
      </div>
      <ul className="process-grid">
        <li>
          {tileIcon}
          <h3>tilework</h3>
          <p>
            proper tile installation involves meticulous surface prep, precise
            alignment, and the use of high-quality adhesives and grouts.
          </p>
        </li>
        <li>
          {waterIcon}
          <h3>waterproofing</h3>
          <p>
            this process involves applying high-quality waterproof membranes and
            sealants to all wet areas. we meet and exceed the tcna nationally
            recognized standards for waterproof enclosures.
          </p>
        </li>
        <li>
          {matIcon}
          <h3>premium materials</h3>
          <p>
            high-quality fixtures, tiles, and finishes not only enhance the
            overall look but also withstand daily wear and tear better than
            standard options.
          </p>
        </li>
        <li>
          {installIcon}
          <h3>precision installs</h3>
          <p>
            precisions installs ensure tiles are evenly spaced and securely set,
            preventing issues like cracking or water damage.
          </p>
        </li>
      </ul>
    </div>
  )
}

export default ProcessComponent
