import React from "react"
import ReactDOM from "react-dom/client"
import Revive from "./revive"
import gsap from "gsap"
import { useGSAP } from "@gsap/react"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"

gsap.registerPlugin(useGSAP)
gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollToPlugin)

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(<Revive />)
