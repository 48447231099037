import { useEffect, useState } from "react"
import BannerComponent from "./components/banner.component"
import CalendarComponent from "./components/calendar.component"
import FooterComponent from "./components/footer.component"
import HeaderComponent from "./components/header.component"
import ImageGridComponent from "./components/image-grid.component"
import ProcessComponent from "./components/process.component"
import QuoteComponent from "./components/quote.component"
import TextComponent from "./components/text.component"
import { aboutCopy } from "./constants"
import "./revive.css"
import ContactComponent from "./components/contact.component"

const Revive = () => {
  const [showContact, setShowContact] = useState()
  const [dismissContact, setDismissContact] = useState()
  const [scheduleOnly, setScheduleOnly] = useState()

  useEffect(() => {
    if (window.location.hostname.indexOf("schedule") > -1) {
      setScheduleOnly(true)
    }
  }, [])

  const handleDismissContact = () => {
    if (showContact) {
      setShowContact(false)
      setDismissContact(true)
    }
  }

  const handleShowContact = () => {
    setShowContact(true)
    setDismissContact(false)
  }

  return scheduleOnly ? (
    <div className="revive-app white">
      <CalendarComponent id="schedule" />
    </div>
  ) : (
    <div className="revive-app">
      <div
        className={showContact ? "revive-app-main slide" : "revive-app-main"}
        onClick={handleDismissContact}
      >
        <HeaderComponent onContact={handleShowContact} />
        <BannerComponent
          includeLogo
          copy="expertly crafted for your peace of mind."
          button={{ copy: "learn more", action: "process" }}
          image="/images/banner.png"
          id="intro"
          usePin
        />

        <TextComponent
          header="about us"
          id="about"
          copy={aboutCopy}
          usePattern
        />

        <ImageGridComponent
          images={[
            "/images/grid/1.jpg",
            "/images/grid/2.jpg",
            "/images/grid/3.jpg",
            "/images/grid/4.jpg",
            "/images/grid/5.jpg",
            "/images/grid/6.jpg",
            "/images/grid/7.jpg",
            "/images/grid/8.jpg",
            "/images/grid/9.jpg",
            "/images/grid/10.jpg",
          ]}
        />
        <ProcessComponent id="process" />
        <QuoteComponent />
        <CalendarComponent id="schedule" />
        <FooterComponent />
      </div>
      <div
        className={
          showContact ? "revive-app-contact slide" : "revive-app-contact"
        }
      >
        {
          <ContactComponent
            active={showContact}
            onCancel={dismissContact}
            onDone={() => setShowContact(false)}
          />
        }
      </div>
    </div>
  )
}

export default Revive
