import { useGSAP } from "@gsap/react"
import "./text.component.css"
import gsap from "gsap"

const TextComponent = (props) => {
  const { header, copy, usePattern, id } = props

  useGSAP(() => {
    if (usePattern) {
      gsap.fromTo(
        ".text-container-pattern",
        {
          opacity: 0,
        },
        {
          scrollTrigger: {
            trigger: ".revive-text-container",
            start: "top 40%",
            end: "bottom 100%",
            scroller: ".revive-app-main",
          },
          opacity: 1,
        }
      )
    }

    if (window.innerWidth > 900) {
      gsap.fromTo(
        ".text-container-content",
        {
          yPercent: 10,
          opacity: 0,
          translateZ: -10,
        },
        {
          yPercent: 0,
          opacity: 1,
          translateZ: 0,
          scrollTrigger: {
            trigger: ".revive-text-container",
            start: "top 90%",
            end: "bottom 100%",
            scroller: ".revive-app-main",
            scrub: 2,
          },
        }
      )
    } else {
      gsap.fromTo(
        ".text-container-content",
        {
          yPercent: 10,
          opacity: 0,
          translateZ: -10,
        },
        {
          yPercent: 0,
          opacity: 1,
          translateZ: 0,
          delay: 0.5,
          duration: 0.6,
          ease: "Power3.inOut",
        }
      )
    }
  }, [usePattern])

  return (
    <div className="revive-text-container" id={id}>
      <div className="text-container-content">
        <h2>{header}</h2>
        <p dangerouslySetInnerHTML={{ __html: copy }} />
      </div>
      {usePattern && <div className="text-container-pattern" />}
    </div>
  )
}

export default TextComponent
