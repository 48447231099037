export const aboutCopy = `<p>
at <strong>revive bathworks</strong>, we understand that a bathroom
renovation is not just a project, but a transformative journey for your
home. with our commitment to exceptional craftsmanship and the use of only
premium materials, we ensure that every step is seamless and stress-free.
our team of experienced professionals delivers precision installations
with meticulous attention to detail, giving you peace of mind and a
luxurious space that is built to last. schedule a consultation today and
let us turn your bathroom into a sanctuary of comfort and relaxation.
</p>`

export const igLink = "https://www.instagram.com/revive_bathworks/"
