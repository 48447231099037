import { useGSAP } from "@gsap/react"
import "./image-grid.component.css"
import gsap from "gsap"

const ImageGridComponent = (props) => {
  const { images } = props

  useGSAP(() => {
    if (window.innerWidth < 900) return

    const tiles = document.querySelectorAll(".revive-image-grid li")
    tiles.forEach((e, i) => {
      const offset = 40 + (tiles.length - i) * 4
      const end = 10 + offset
      gsap.fromTo(
        e,
        {
          yPercent: 10,
          opacity: 0,
          scale: 0.99,
        },
        {
          opacity: 1,
          yPercent: 0,
          scale: 1,
          scrollTrigger: {
            trigger: ".revive-image-grid",
            start: `top ${offset}%`,
            end: `bottom 90%+=${end}px`,
            scrub: 3,
            scroller: ".revive-app-main",
          },
        }
      )
    })
  }, [])

  return (
    <ul className="revive-image-grid">
      {images.map((i) => {
        return (
          <li key={i}>
            <img src={i} alt="revive bathworks gallery" />
          </li>
        )
      })}
    </ul>
  )
}

export default ImageGridComponent
