import "./footer.component.css"

const FooterComponent = () => {
  const year = new Date().getFullYear()
  return (
    <footer>
      <div className="footer-copy">
        &copy;{year} revive bathworks - all rights reserved
      </div>
      <div className="footer-design">
        built by{" "}
        <a href="https://one-two.co" target="_blank" rel="noreferrer">
          one-two
        </a>
      </div>
    </footer>
  )
}

export default FooterComponent
