import { useGSAP } from "@gsap/react"
import "./header.component.css"
import { useState } from "react"
import gsap from "gsap"
import { closeIcon, igIcon, menuIcon } from "../icons"
import { igLink } from "../constants"

const HeaderComponent = (props) => {
  const { onContact } = props
  const [showMenu, setShowMenu] = useState()

  const handlePageClick = (dest) => {
    setShowMenu(false)
    gsap.to(".revive-app-main", {
      duration: 0.6,
      scrollTo: {
        y: `#${dest}`,
        offsetY: 150,
      },
      ease: "Power1.easeInOut",
    })
  }

  const handleToggleMobileMenu = () => {
    if (!showMenu) {
      setShowMenu(true)
      setTimeout(() => {
        const el = document.querySelector(".mobile-menu")
        el.classList.remove("collapsed")
      })
    } else {
      const el = document.querySelector(".mobile-menu")
      el.classList.add("collapsed")

      setTimeout(() => {
        setShowMenu(false)
      }, 400)
    }
  }

  const handleContactClick = () => {
    onContact()
    setShowMenu(false)
  }

  const createMenu = (desktop) => {
    return (
      <ul className={desktop ? "desktop-only" : ""}>
        <li onClick={() => handlePageClick("about")}>about us</li>
        <li onClick={() => handlePageClick("process")}>process</li>
        <li onClick={() => handlePageClick("schedule")}>
          schedule a consultation
        </li>
        {!desktop && <li onClick={handleContactClick}>contact</li>}
        {!desktop && (
          <li>
            <a href={igLink} target="_blank" rel="noreferrer">
              instagram
            </a>
          </li>
        )}
      </ul>
    )
  }

  useGSAP(() => {
    gsap.fromTo(
      "header",
      {
        yPercent: -100,
        scaleY: 0.1,
        opacity: 0,
      },
      {
        yPercent: 0,
        ease: "power2.inOut",
        duration: 0.75,
        delay: 0.25,
        scaleY: 1,
        opacity: 1,
      }
    )
  }, [])

  return (
    <header>
      {createMenu(true)}
      <a
        className="revive-ig desktop-only"
        href={igLink}
        target="_blank"
        rel="noreferrer"
      >
        {igIcon}
      </a>
      <button
        className="revive-primary-btn desktop-only"
        onClick={handleContactClick}
      >
        contact us
      </button>
      <button
        className="mobile-only mobile-menu-btn"
        onClick={handleToggleMobileMenu}
      >
        {showMenu ? closeIcon : menuIcon}
      </button>

      {/* Mobile Menu */}
      {showMenu && <div className="mobile-menu collapsed">{createMenu()}</div>}
    </header>
  )
}

export default HeaderComponent
