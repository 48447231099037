import { logoMark } from "../icons"
import "./banner.component.css"
import { useGSAP } from "@gsap/react"
import gsap from "gsap"

const BannerComponent = (props) => {
  const { includeLogo, button, copy, image, usePin, id } = props

  useGSAP(() => {
    if (includeLogo) {
      gsap.fromTo(
        ".revive-banner svg",
        {
          opacity: 0,
          yPercent: 100,
          scale: 0.9,
        },
        {
          opacity: 1,
          duration: 0.75,
          ease: "Power2.easeInOut",
          yPercent: 0,
          scale: 1,
        }
      )
    }

    if (copy) {
      gsap.fromTo(
        ".revive-banner h2",
        {
          opacity: 0,
          yPercent: 100,
          scale: 0.9,
        },
        {
          opacity: 1,
          duration: 0.75,
          ease: "Power2.easeInOut",
          yPercent: 0,
          scale: 1,
          delay: 0.1,
        }
      )
    }

    if (button) {
      gsap.fromTo(
        ".revive-banner button",
        {
          opacity: 0,
          yPercent: 100,
          scale: 0.9,
        },
        {
          opacity: 1,
          duration: 0.75,
          ease: "Power2.easeInOut",
          yPercent: 0,
          scale: 1,
          delay: 0.15,
        }
      )
    }

    if (usePin) {
      gsap.to(`#${id}`, {
        scrollTrigger: {
          trigger: `#${id}`,
          start: "top top",
          end: "bottom 20%",
          scroller: ".revive-app-main",
          onUpdate: (e) => {
            const { progress } = e
            const theProgress = progress * 100
            const final = Math.max(50 - theProgress, 0)
            document.getElementById(
              id
            ).style.backgroundPosition = `50% ${final}%`
          },
        },
      })
    } else {
      gsap.fromTo(
        `#${id}`,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.75,
          scrollTrigger: {
            trigger: `#${id}`,
            start: "top 50%",
            end: "bottom 50%",
          },
        }
      )
    }
  }, [includeLogo, copy, button, usePin])

  const handleButtonClick = (dest) => {
    gsap.to(".revive-app-main", {
      duration: 0.6,
      scrollTo: {
        y: `#${dest}`,
        offsetY: 150,
      },
      ease: "Power1.easeInOut",
    })
  }

  return (
    <div
      className="revive-banner"
      id={id}
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className="banner-content">
        {includeLogo && logoMark}
        {copy && <h2>{copy}</h2>}
        {button && (
          <button
            className="revive-primary-btn green"
            onClick={() => handleButtonClick(button.action)}
          >
            {button.copy}
          </button>
        )}
      </div>
    </div>
  )
}

export default BannerComponent
