import { useGSAP } from "@gsap/react"
import { leftQuoteIcon, rightQuoteIcon } from "../icons"
import "./quote.component.css"
import gsap from "gsap"

const QuoteComponent = () => {
  useGSAP(() => {
    gsap.fromTo(
      `.revive-quote`,
      {
        opacity: 0,
        scale: 0.95,
      },
      {
        opacity: 1,
        scale: 1,
        scrollTrigger: {
          trigger: `.revive-quote`,
          start: "top 50%",
          end: "bottom 80%",
          scrub: 2,
          scroller: ".revive-app-main",
        },
      }
    )
  }, [])
  return (
    <div className="revive-quote">
      <div className="revive-quote-person">
        <img src="/images/alex.png" alt="alex bergland - founder" />
        <div className="revive-quote-person-info">
          <h3>alex bergland</h3>
          <h4>founder</h4>
        </div>
      </div>
      <div className="revive-quote-block">
        <div className="revive-left-quote">{leftQuoteIcon}</div>
        <p>
          our passion for crafting exceptional bathroom spaces is succeeded only
          by our dedication to providing unmatched service. our team of experts
          are here to bring your vision to life with precision, care, and
          attention to detail. thank you for choosing revive — we can't wait to
          create something extraordinary together.
        </p>
        <div className="revive-right-quote">{rightQuoteIcon}</div>
      </div>
    </div>
  )
}

export default QuoteComponent
