import { useEffect, useState } from "react"
import Cal, { getCalApi } from "@calcom/embed-react"
import "./calendar.component.css"

const CalendarComponent = (props) => {
  const { id } = props
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const createCal = async () => {
      try {
        const cal = await getCalApi({})
        cal("ui", {
          theme: "light",
          styles: { branding: { brandColor: "#7e6c6c" } },
          hideEventTypeDetails: false,
          layout: "month_view",
        })

        setLoading(false)
      } catch (e) {
        console.log("Error setting up calendar", e)
      }
    }

    createCal()
  }, [])

  return (
    <div className="revive-calendar" id={id}>
      <h2>schedule a consultation</h2>
      {!loading && (
        <Cal
          calLink="revive-bathworks/30min"
          style={{ width: "100%", height: "100%", overflow: "scroll" }}
          config={{ layout: "month_view" }}
        />
      )}
    </div>
  )
}

export default CalendarComponent
